import { createGlobalStyle, ThemeProvider as Provider } from "styled-components";

import "filepond/dist/filepond.min.css";
import "font-awesome/css/font-awesome.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@resources/style.css";

const theme = {};

const GlobalStyles = createGlobalStyle``;
export default GlobalStyles;
export const Theme = theme;
export const ThemeProvider = Provider;
