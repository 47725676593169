import axios from "axios";
import swal from "sweetalert";
import { v4 as uuid } from "uuid";
import path from "path";
import moment from "moment";
import "aws-sdk/dist/aws-sdk";

import _CK from "@util/cookie";

const api = {};
api.get = (request) => {
    request.method = "GET";
    return api.send(request);
};
api.post = (request) => {
    request.method = "POST";
    return api.send(request);
};
api.put = (request) => {
    request.method = "PUT";
    return api.send(request);
};
api.delete = (request) => {
    request.method = "DELETE";
    return api.send(request);
};
api.send = (request) => {
    const { url, path, data, apiKey } = request;
    const { config } = request;
    let { auth, method } = request;

    if (!auth) auth = _CK.isLogin() ? _CK.getAuth() : "";
    if (!method) method = "GET";

    return new Promise((resolve, reject) => {
        const req = {
            method,
            url: url ? url : `${process.env.API_URL}${path}`,
            headers: {
                apikey: apiKey ? apiKey : process.env.API_KEY,
                authorization: auth,
            },
            data: method.toUpperCase() !== "GET" ? data : null,
            params: method.toUpperCase() === "GET" ? data : null,
            onUploadProgress: function (progressEvent) {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                //	axios 에 onDownloadProgress & onUploadProgress 를 사용하면
                //	해당 데이터의 total 과 load 를 사용할 수 있습니다. 이를 활용해서 progressbar 의 value 로 사용합니다.
                // console.log(progressEvent.lengthComputable);
                // console.log(percentCompleted);
                // console.log(progressEvent.loaded, progressEvent.total, percentCompleted);
                config && config.setLoadPercent && config.setLoadPercent(percentCompleted);
            },
        };
        if (path?.startsWith("/v1/partner")) {
            const site = _CK.getSite();
            if (!site || !site.idx) {
                return reject({ message: "파트너 사이트 정보가 없습니다." });
            }
            req.headers["site"] = site.idx;
        }

        axios(req)
            .then((res) => {
                if (res.status !== 200) {
                    throw new Error(res.statusText);
                }

                const { data } = res;

                if (method === "POST" && data.error === -999) {
                    swal({ title: "경고", text: data.message });
                    throw data;
                }
                if (data.error !== 0) {
                    throw data;
                }

                resolve && resolve(data);
            })
            .catch((e) => reject(e));
    });
};

api.alert = ({ message }) => {
    return new Promise((resolve, reject) => {
        swal({ title: "알림", text: message }).then(resolve).catch(reject);
    });
};

api.iam = (auth) => {
    const req = {
        path: "/iam",
        method: "GET",
        auth: auth ? auth : _CK.getAuth(),
    };
    return api.send(req);
};

api.loadUploadKeys = (isPrivate) => {
    const req = {
        path: "/upload",
        method: "GET",
        data: {
            security: isPrivate ? "Y" : "N",
        },
    };
    return api.send(req);
};

api.upload = ({ aws, file, path: location, prefix, config }) => {
    return new Promise((resolve, reject) => {
        if (!aws) {
            reject(new Error("AWS 정보를 확인해 주세요."));
            return;
        }
        if (!file) {
            reject(new Error("파일을 입력해 주세요."));
            return;
        }
        const s3 = new AWS.S3({
            accessKeyId: aws.accessKey,
            secretAccessKey: aws.secretKey,
            region: aws.region,
        });

        const uniqueId = uuid().replace(/-/gi, "").toLowerCase();
        const uploadPath = `${prefix ? `${prefix}/` : ""}${location ? location : moment().format("YYYYMMDD")}/${uniqueId}${path.extname(file.name)}`;

        s3.upload(
            {
                Bucket: aws.bucket,
                Key: uploadPath,
                Body: file,
                ContentType: file.type,
                ACL: "private",
            },
            async (err, data) => {
                if (err) {
                    reject(err);
                    return;
                }

                const { Bucket, Key } = data;
                const uploaded = {
                    bucket: Bucket,
                    path: Key,
                    name: file.name,
                    size: file.size,
                    type: file.type,
                };
                const req = { method: "POST", path: "/upload", data: uploaded, config };
                const res = await api.send(req);
                resolve(res);
            }
        );
    });
};
export default api;
