import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import _CK from "@util/cookie";

import Layout from "@layout/Default";

import IndexPage from "./pages";

export default () => {
    return (
        <Router>
            <Layout>
                <Routes>
                    <Route path="/" element={<IndexPage />} />
                    <Route path="*" element={<Navigate replace to="/" />} />
                </Routes>
            </Layout>
        </Router>
    );
};
