import React from "react";
import { useContext } from "react";

import { AppContext } from "@component/AppProvider";
import _CK from "@util/cookie";
import _CFG from "@config/defaults";

const Header = (props) => {
    const { saveIam } = useContext(AppContext);

    const handleLogout = (e) => {
        e.preventDefault();

        saveIam(null);
        _CK.clearAuth();

        document.location.href = "/";
    };

    return <header>header</header>;
};

export default Header;
