import { useEffect, useContext } from "react";

import { AppContext } from "@component/AppProvider";

import _CK from "@util/cookie";
import _API from "@util/api";

const useIam = (options) => {
    const { onSuccess, onError } = options || {};
    const { saveIam } = useContext(AppContext);

    const loadIam = () => {
        if (!_CK.isLogin()) return onSuccess && onSuccess(null);

        _API.iam()
            .then((res) => res.data)
            .then((data) => {
                const { iam } = data;

                if (!iam || iam.status !== "Y") {
                    saveIam(null);
                    _CK.clearAuth();
                    throw { message: "권한이 없습니다." };
                }
                saveIam(iam);
                onSuccess && onSuccess(data);
            })
            .catch((e) => onError && onError(e));
    };

    useEffect(() => {
        loadIam();
    }, []);
};

export default useIam;
