import React, { useState, Fragment } from "react";

import _CK from "@util/cookie";
import _API from "@util/api";

import useIam from "@hook/useIam";

import Loading from "@component/UI/Loading";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children, breadcrumb }) => {
    const [loaded, setLoaded] = useState(false);

    useIam({
        onSuccess: () => {
            setLoaded(true);
        },
        onError: (e) => {
            _CK.clearAuth();
            document.location.href = "/";
        },
    });

    if (!loaded) {
        return <Loading dark />;
    }

    return (
        <Fragment>
            <Header />
            {children}
            <Footer />
        </Fragment>
    );
};

export default Layout;
