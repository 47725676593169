import React, { createContext, useState } from "react";

const AppContext = createContext();
const AppProvider = ({ iam: iamInProps, children }) => {
    const [iam, setIam] = useState(iamInProps);

    const saveIam = (iam) => {
        setIam(iam);
    };

    return <AppContext.Provider value={{ iam, saveIam }}>{children}</AppContext.Provider>;
};

export { AppContext, AppProvider };
